import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

import { StaticImage } from "gatsby-plugin-image"
import Container from "react-bootstrap/Container"
import Card from "react-bootstrap/Card"
import { BsPatchQuestion, BsArrowReturnRight, BsGraphUp } from "react-icons/bs"
import { FaArrowAltCircleDown } from "react-icons/fa"
const MobileApp = () => {
  return (
    <Layout>
      <Seo
        title="Mobile Application Development Services"
        description="We build mobile apps across Android and iOS platforms. We deliver innovative business solutions that are flexible and optimized to maximize efficiency and return."
        keyword="Mobile App, Mobile Application"
      />
      <Container fluid className="min-vh-100 position-relative">
        <br />
        <br />
        <br />
        <div
          style={{ display: "grid" }}
          className="mt-5 my-auto ms-auto align-items-center text-center"
        >
          {/* You can use a GatsbyImage component if the image is dynamic */}
          <span className="d-none d-md-block ms-auto me-auto">
          <StaticImage
            // This is a presentational image, so the alt should be an empty string
            alt="mobile application development image"
            // Assisi, Perúgia, Itália by Bernardo Ferrari, via Unsplash
            src={"../images/mobileapp.png"}
            placeholder={"tracedSVG"}
            formats={["auto", "webp", "avif"]}
          />
          </span>
          <span className="d-block d-md-none ms-auto me-auto">
          <StaticImage
            // This is a presentational image, so the alt should be an empty string
            alt="mobile application development image"
            // Assisi, Perúgia, Itália by Bernardo Ferrari, via Unsplash
            src={"../images/mobileapp_mobile.png"}
            placeholder={"tracedSVG"}
            formats={["auto", "webp", "avif"]}
          />
          </span>
          <div
            style={{
              // By using the same grid area for both, they are stacked on top of each other
              gridArea: "1/1",
              position: "relative",
              // This centers the other elements inside the hero component
              placeItems: "center",
              display: "grid",
            }}
          >
            {/* Any content here will be centered in the component */}
            <h1 className="text3d fs-3">Mobile Application</h1>
            <br />
            <br />
          </div>
          <h2 className="h2 luminance mt-4"><br />
              Custom App for Smart Device that brings world to your fingertip!
          </h2>
        </div>
        <div className="position-absolute bottom-0 start-50 text-white mb-1">
          <FaArrowAltCircleDown className="bouncearrow" />
        </div>
      </Container>
      <Container fluid className="bg-white">
        <br />
        <br />
        <br />
        <br />
        <br />
        <Container className="align-items-center fs-5 text-center">
          <p
            className="bg-text fs-5"
            data-bg-text="MOBILE APP"
            data-aos="zoom-in-right"
          >
            The world is dominated by those small little handsets called mobile
            phones and smartphones. Everyone is spending almost their entire day
            just tapping those bright screens. And with the option to buy almost
            everything at the click of a button, the mobiles have indeed become
            the unofficial shopping partner to men and women alike. And in that
            line, Mobile apps have become a critical part of today’s digital
            marketing plans. At Softcode, we offer design and development of
            comprehensive and scalable mobile apps to suit the business needs of
            every industry. <br />
            <br /> If you are still wondering whether you should get a mobile
            app for your business, then you may want to look at the latest
            statistics on the mobile apps. According to recent studies, 70% of
            top retailers, 75% of casual dining brands, 78% of fast food chains,
            59% of specialty stores, and many more have mobile apps. It is not
            just about gaining a competitive edge anymore. A mobile app is
            definitely necessary if you are looking to stay in the race. <br />
            <br /> With a robust and experienced team of developers and
            designers, we assure you that our team will deliver the best mobile
            solution to meet all the challenges in the present and in the
            future. We make use of the most advanced technology to construct
            bespoke mobile applications for consumer needs and their
            initiatives. Our team is well versed with the different mobile
            platforms including Windows, iOS and Android and can handle
            delivering individual or a comprehensive adaptable solution for all
            platforms.
          </p>
        </Container>
        <br />
        <br />
        <br />
        <br />
        <br />
      </Container>
      <Container fluid>
        <br />
        <Container>
          <br />
          <h2
            className="text-center bg-textlight"
            data-aos="flip-left"
          >
            NATIVE MOBILE APP
          </h2>
          <br />
          <br />
          <Container className="text-white text-wrap text-center fs-5">
            Native mobile apps are created to be "native" to a single platform,
            such as Apple's iOS, Google's Android, or Microsoft's Windows Phone.
            Because it tends to optimize the user experience, the native
            platform can be useful. It can work more swiftly and naturally
            because it was created exclusively for the platform.
            <br />
            <br />
            If you are looking to provide a classy app for your mobile and
            tablet users, you need an expert who is well versed with the
            iOS/Android platform and the programming languages compatible with
            the platform. You want a team that is familiar with languages like
            ASIHTTP, iRate and technologies like Mac OS X framework to deliver
            you the ultimate application for phone, tablet and wearable users.
            With our team of skilled designers and developers who have prior
            experience and certifications in multiple programming languages,
            tools, interface designing and frameworks, you will have an
            incomparable and holistic solution at your hand.
            <br />
            <br />
            <br />
            <br />
          </Container>
        </Container>
      </Container>
      <Container fluid className="bg-white text-center">
        <br />
        <br />
        <Container className="align-items-center">
          <h2 className="text-center bg-textdark" data-aos="flip-up">
            HYBRID MOBILE APP
          </h2>
          <br />
          <br />
          <Container className="text-center text-wrap fs-5">
            These apps are similar to native apps in that they can be installed
            on devices, but they are run through web browsers. HTML5 is the
            programming language used to create any hybrid apps. Hybrid apps are
            not as quick or as dependable as native apps, but they can
            streamline the development process more effectively. Your company
            can save time and resources by not having to develop and maintain
            apps for several platforms. It's perfect for apps that are mostly
            content-driven.
            <br />
            <br />
            As part of our mobile app development services, we offer you
            revolutionary applications that are designed with friendly
            interfaces, flexible features and multilateral aspects. We do not
            just create fluid apps but we also make creative ones that will work
            flawlessly with the website. With an experience of more than a
            decade, our team comprises of developers who have the initiative,
            passion and expertise that you need from a mobile app developer.
            <br />
            <br />
            <br />
            <br />
          </Container>
        </Container>
      </Container>

      <Container fluid>
        <br />
        <br />
        <h2
          className="text-center bg-textlight"
          data-aos="flip-left"
        >
          WEB APP
        </h2>
        <br />
        <br />
        <Container className="text-white text-wrap text-center fs-5">
          When browsed on a mobile device, responsive websites transform to a
          different look. On the other hand, adaptive web apps resize to match
          the various screen sizes of mobile devices. The design does not change
          for these apps. Web applications may be written in any programming
          language, but they can't utilise hardware on mobile devices or be sold
          in any app store.
          <br />
          <br />
          <br />
          <br />
        </Container>
      </Container>

      <Container fluid className="bg-white text-center align-items-center">
        <br />
        <br />
        <br />
        <br />
        <Container className="text-center text-wrap fs-5">
          <Card style={{cursor: "default"}}>
            <Card.Header>
              <BsPatchQuestion />
              &nbsp; Why us?
            </Card.Header>
            <Card.Body>
              <Card.Text className="text-start">
                    <BsArrowReturnRight />
                    &nbsp; We service advanced methodologies and strategies to
                    provide incomparable app design.
<br />
                    <BsArrowReturnRight />
                    &nbsp; We do not rewrite apps for every platform but we
                    write a single program that is compatible with all platforms
                    saving cost for our clients and generating revenue.
<br />
                    <BsArrowReturnRight />
                    &nbsp; We have a sturdy crew of talented app designers and
                    experienced mobile app developers who are well-knowledgeable
                    to build apps that are game-changing in scale and scope.
<br />
                    <BsArrowReturnRight />
                    &nbsp; At Softcode, we develop apps that are compatible with
                    the real time website and enhances computer experience.
<br />
                    <BsArrowReturnRight />
                    &nbsp; A highly diverse team of developers and designers
                    that craft nothing but the best for you.
<br />
                    <BsArrowReturnRight />
                    &nbsp; We have the ability to create apps for any platform
                    in many languages.
              </Card.Text>
            </Card.Body>
          </Card>
          <br />
          In short, “We work for you, we work with you.” Mobile applications are
          where people prefer to spend more time as compared to web from mobile
          device or desktop. Our team designs compatible, futuristic,
          qualitative and enactment-centric mobile apps.
          <br />
          <br />
          We not only offer brilliantly developed apps, but also help you with
          the reliable post launch support.
          <br />
          Our designer team is highly classified and they shall plan the design
          that will help you top the charts any day.
          <br />
          <br />
        </Container>
      </Container>
      <Container fluid>
        <br />
        <br />
        <Container className="text-white text-wrap text-center fs-5">
          <Card style={{cursor: "default"}}>
            <Card.Header className="text-dark">
              <BsGraphUp />
              &nbsp; What do you get?
            </Card.Header>
            <Card.Body>
              <Card.Text className="text-start text-dark">
                    <BsArrowReturnRight />
                    &nbsp; Carefully tested Bug Free apps.
<br />
                    <BsArrowReturnRight />
                    &nbsp; Ready to launch products.
<br />
                    <BsArrowReturnRight />
                    &nbsp; Optimized projects.
<br />
                    <BsArrowReturnRight />
                    &nbsp; Timely delivery.
<br />
                    <BsArrowReturnRight />
                    &nbsp; Fully-agreeable & guaranteed products.
              </Card.Text>
            </Card.Body>
          </Card>
          <br />
          <br />
          We offer all this and much more at a competitive price to ensure
          everyone can reap from the benefits of having a mobile app.
          <br />
          <br />
          Contact us as soon as possible to transform your raw mobile
          application idea into reality with the help of our experts. When you
          see your idea become alive in your hands, installed for the first time
          in your pocket device, you shall clearly observe the passion and
          persistence with which we have fashioned the next big thing for mobile
          hosting stores. Come to us with your concepts and our technologically
          zealot team will convert them into enticing apps. We listen to your
          terms, take notes, develop, test and then guide you as well and take
          your occupational desires to the level where you dream them to be.
          <br />
          <br />
          We have created successful apps and we would like to have yours in the
          list too! <br />
          <br />
        </Container>
      </Container>
    </Layout>
  )
}

export default MobileApp
